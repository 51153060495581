import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroSkuEditarPlantilla'
})

export class FiltroSkuEditarPlantillaPipe implements PipeTransform {

  transform(snapshotArr: any, searchValue: string) {
    if (!snapshotArr) {
      return [];
    }
    if (!searchValue) {
      return snapshotArr;
    }
    if (snapshotArr && searchValue) {
      return snapshotArr.filter(snapshot => {
        return snapshot.Descripcion.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
  }

}
