import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { JqxGridModule } from '../../ng-modules/jqx-grid/jqx-grid.module';
import { ModalModule } from 'modal';
import { UpiComponent } from '../upi/upi/upi.component';
import { GridAsignarCantidadASkuComponent } from './grid-asignar-cantidad-a-sku/grid-asignar-cantidad-a-sku.component';
import { GridDividirSkuComponent } from './grid-dividir-sku/grid-dividir-sku.component';
import { GridDespachosComponent } from './grid-despachos/grid-despachos.component';
import { FiltroCheckSkuPipe } from './pipes/filtro-check-sku/filtro-check-sku.pipe';
import { FiltroskuPipe } from './pipes/filtro-sku/filtrosku.pipe';
import { GridGestionesMesComponent } from './grid-gestiones-mes/grid-gestiones-mes.component';
import { GridGestionesSemanasComponent } from './grid-gestiones-semanas/grid-gestiones-semanas.component';
import { GridDespachosAsignadosMesComponent } from './grid-despachos-asignados-mes/grid-despachos-asignados-mes.component';
import { GridDespachosAsignadosSemanasComponent } from './grid-despachos-asignados-semanas/grid-despachos-asignados-semanas.component';
import { GridDespachosSinAsignarMesComponent } from './grid-despachos-sin-asignar-mes/grid-despachos-sin-asignar-mes.component';
import { GridDespachosSinAsignarSemanasComponent } from './grid-despachos-sin-asignar-semanas/grid-despachos-sin-asignar-semanas.component';
import { GridGestionesMensualSkuComponent } from './grid-gestiones-mensual-sku/grid-gestiones-mensual-sku.component';
import { GridDespachosAsignadosMensualSkuComponent } from './grid-despachos-asignados-mensual-sku/grid-despachos-asignados-mensual-sku.component';
import { GridDespachosSinAsignarMensualSkuComponent } from './grid-despachos-sin-asignar-mensual-sku/grid-despachos-sin-asignar-mensual-sku.component';
import { GridOrdenesDeCompraMesComponent } from './grid-ordenes-de-compra-mes/grid-ordenes-de-compra-mes.component';
import { GridOrdenesDeCompraMensualComponent } from './grid-ordenes-de-compra-mensual/grid-ordenes-de-compra-mensual.component';
import { GridGestionesFinalizadasMesComponent } from './grid-gestiones-finalizadas-mes/grid-gestiones-finalizadas-mes.component';
import { GridGestionesNoFinalizadasMesComponent } from './grid-gestiones-no-finalizadas-mes/grid-gestiones-no-finalizadas-mes.component';
import { GridGestionesFinalizadasSemanasComponent } from './grid-gestiones-finalizadas-semanas/grid-gestiones-finalizadas-semanas.component';
import { GridGestionesNoFinalizadasSemanasComponent } from './grid-gestiones-no-finalizadas-semanas/grid-gestiones-no-finalizadas-semanas.component';
import { GridPlantillasComponent } from './grid-plantillas/grid-plantillas.component';
import { FiltroSkuPlantillaPipe } from './pipes/filtro-sku-plantilla/filtro-sku-plantilla.pipe';
import { FiltroSkuEditarPlantillaPipe } from './pipes/filtro-sku-editar-plantilla/filtro-sku-editar-plantilla.pipe';

const routes: Routes = [
  { path: 'home/Index', component: UpiComponent }
]
const routing = RouterModule.forChild(routes);

@NgModule({
  declarations: [
    UpiComponent,
    GridAsignarCantidadASkuComponent,
    GridDividirSkuComponent,
    GridDespachosComponent,    
    FiltroCheckSkuPipe,
    FiltroskuPipe,
    GridGestionesMesComponent,
    GridGestionesSemanasComponent,
    GridDespachosAsignadosMesComponent,
    GridDespachosAsignadosSemanasComponent,
    GridDespachosSinAsignarMesComponent,
    GridDespachosSinAsignarSemanasComponent,
    GridGestionesMensualSkuComponent,
    GridDespachosAsignadosMensualSkuComponent,
    GridDespachosSinAsignarMensualSkuComponent,
    GridOrdenesDeCompraMesComponent,
    GridOrdenesDeCompraMensualComponent,
    GridGestionesFinalizadasMesComponent,
    GridGestionesNoFinalizadasMesComponent,
    GridGestionesFinalizadasSemanasComponent,
    GridGestionesNoFinalizadasSemanasComponent,
    GridPlantillasComponent,
    FiltroSkuPlantillaPipe,
    FiltroSkuEditarPlantillaPipe    
    ],
  imports: [
    routing,
    CommonModule,
    FormsModule,
    NgSelectModule,
    JqxGridModule,
    ModalModule
  ]
})

export class UpiModule { }
